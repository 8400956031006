.pin-container {
  display: flex;
  justify-content: center;
  gap: 17px;
}

.pin-input {
  pointer-events: none;
  width: 2rem;
  height: 4rem; 
  background: transparent;
  text-align: center;
  color: #F0F0F0;
  font-size: 1.8rem;
  border: none;
  border-bottom: 0.2rem solid white;
  box-sizing: border-box;
}

.pin-input:focus {
  outline: none;
  border-color: #00BCD4;
  pointer-events: auto;
}

@media (min-width: 960px) { 
  .pin-container {
    margin-top: 30px;
  }
}

@media (max-width: 600px) {
  .pin-input {
    width: 3.5rem; 
    height: 4.5rem; 
    font-size: 2.1rem; 
  }
}

