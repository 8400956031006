.materialBackdrop {
    color: white;
    margin: 132px 0 auto 0;
}

.materialBackdrop p {
    display: block;
    position: fixed;
    text-align: center;
    max-width: 90%;
}