/* colors */
:root {
    --main-background: #343434;
    --secondary-background: #707070;
    --main-text: #EFEFEF;
    --main-error: #FF0000;
    --shadow: rgba(0, 0, 0, 0.25);
    --shadow-dark: rgba(0, 0, 0, 0.75);
    --disabled-icon: #8D8D8D;
}

.helpWrapper {
    max-height: 600px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: white;
    border-radius: 10px;
    z-index: 5000;
}

.helpContent {
    padding: 10px 15px;
}

.helpTooltip {
    display: inline-block;
    margin-left: 20px;
}

.helpRow {
    margin-bottom: 5px;
}