/* 
DayPilot Scheduler Theme
https://themes.daypilot.org/scheduler/theme/24rzow
Theme Designer Version: 2019.08.13.78552
*/
.t_main {
    border: 1px solid #ffffff;
}

.t_main,
.t_main td {
    font-family: Tahoma, Arial, Helvetica, sans-serif;
    font-size: 12px;
}

.t_event {
}

.t_event {
}

.t_event_inner {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: 0px;
    overflow: hidden;
    background-color: #ffffff;
    background: linear-gradient(to bottom, #ffffff 0%, #fafafa);
    padding: 8px 2px;
    border: 1px solid #ffffff;
    display: flex;
    align-items: center;
}

.t_timeheadergroup,
.t_timeheadercol {
    color: #000000;
    background: #ffffff;
}

.t_rowheader,
.t_corner {
    color: #000000;
    background: #ffffff;
}

.t_rowheader_inner {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    padding: 2px;
    display: flex;
    align-items: center;
}

.t_timeheadergroup,
.t_timeheadercol {
}

.t_timeheadergroup_inner,
.t_timeheadercol_inner {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    border-right: 1px solid #ffffff;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.t_timeheadergroup_inner {
    border-bottom: 1px solid #ffffff;
}

.t_divider,
.t_splitter,
.t_divider_horizontal,
.t_resourcedivider {
    background-color: #ffffff;
}

.t_matrix_vertical_line {
    background-color: #fff;
}

.t_matrix_horizontal_line {
    background-color: #e6e6e6;
}

.t_cell {
    background: #ffffff;
}

.t_cell.t_cell_business {
    background: #ffffff;
}

.t_message {
    padding: 10px;
    opacity: 0.9;
    filter: alpha(opacity=90);
    color: #ffffff;
    background: #ffa216;
}

.t_shadow_inner {
    background-color: #666666;
    opacity: 0.5;
    filter: alpha(opacity=50);
    height: 100%;
}

.t_event_bar {
    top: 0px;
    left: 0px;
    right: 0px;
    height: 7px;
    background-color: #1066a8; /*disable gap*/
}

.t_event_bar_inner {
    position: absolute;
    height: 7px;
    background-color: #1066a8;
}

.t_matrix_vertical_break {
    background-color: #000;
}

.t_cellparent {
}

.t_tree_image_no_children {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAIAAABv85FHAAAAKXRFWHRDcmVhdGlvbiBUaW1lAHDhIDMwIEkgMjAwOSAwODo0NjozMSArMDEwMClDkt4AAAAHdElNRQfZAR4HLzEyzsCJAAAACXBIWXMAAA7CAAAOwgEVKEqAAAAABGdBTUEAALGPC/xhBQAAADBJREFUeNpjrK6s5uTl/P75OybJ0NLW8h8bAIozgeSxAaA4E1A7VjmgOL31MeLxHwCeXUT0WkFMKAAAAABJRU5ErkJggg==);
}

.t_tree_image_expand {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAIAAABv85FHAAAAKXRFWHRDcmVhdGlvbiBUaW1lAHDhIDMwIEkgMjAwOSAwODo0NjozMSArMDEwMClDkt4AAAAHdElNRQfZAR4HLyUoFBT0AAAACXBIWXMAAA7CAAAOwgEVKEqAAAAABGdBTUEAALGPC/xhBQAAAFJJREFUeNpjrK6s5uTl/P75OybJ0NLW8h8bAIozgeRhgJGREc4GijMBtTNgA0BxFog+uA4IA2gmUJwFog/IgUhAGBB9KPYhA3T74Jog+hjx+A8A1KRQ+AN5vcwAAAAASUVORK5CYII=);
}

.t_tree_image_collapse {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAIAAABv85FHAAAAKXRFWHRDcmVhdGlvbiBUaW1lAHDhIDMwIEkgMjAwOSAwODo0NjozMSArMDEwMClDkt4AAAAHdElNRQfZAR4HLxB+p9DXAAAACXBIWXMAAA7CAAAOwgEVKEqAAAAABGdBTUEAALGPC/xhBQAAAENJREFUeNpjrK6s5uTl/P75OybJ0NLW8h8bAIozgeSxAaA4E1A7VjmgOAtEHyMjI7IE0EygOAtEH5CDqY9c+xjx+A8ANndK9WaZlP4AAAAASUVORK5CYII=);
}

.t_columnheader {
}

.t_columnheader_inner {
    font-weight: bold;
}

.t_columnheader_cell {
    background: linear-gradient(to right, #eeeeee 0%, #dddddd);
}

.t_columnheader_splitter {
    background-color: #666;
    opacity: 0.5;
    filter: alpha(opacity=50);
}

.t_columnheader_cell_inner {
    padding: 2px;
}

.t_timeheader_float {
    display: flex;
    align-items: center;
    justify-content: center;
}

.t_timeheader_float_inner {
    padding: 2px;
}

.t_event_float {
    display: flex;
    align-items: center;
}

.t_event_float_inner {
    padding: 10px 2px 2px 7px;
    padding-left: 9px;
    /*top: -2px;*/
    position: relative;
}

.t_event_float_inner:after {
    content: "";
    border-color: transparent #333333 transparent transparent;
    border-style: solid;
    border-width: 5px;
    width: 0;
    height: 0;
    position: absolute;
    top: 12px;
    left: -4px;
}

.t_event_move_left {
    box-sizing: border-box;
    padding: 2px;
    border: 1px solid #ccc;
    background: #fff;
    background: linear-gradient(to bottom, #ffffff 0%, #eeeeee);
}

.t_event_move_right {
    box-sizing: border-box;
    padding: 2px;
    border: 1px solid #ccc;
    background: #fff;
    background: linear-gradient(to bottom, #ffffff 0%, #eeeeee);
}

.t_event_delete {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAYAAACprHcmAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjExR/NCNwAAAI5JREFUKFNtkLERgCAMRbmzdK8s4gAUlhYOYEHJEJYOYOEwDmGBPxC4kOPfvePy84MGR0RJ2N1A8H3N6DATwSQ57m2ql8NBG+AEM7D+UW+wjdfUPgerYNgB5gOLRHqhcasg84C2QxPMtrUhSqQIhg7ypy9VM2EUZPI/4rQ7rGxqo9sadTegw+UdjeDLAKUfhbaQUVPIfJYAAAAASUVORK5CYII=) center center no-repeat;
    opacity: 0.6;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
    cursor: pointer;
    opacity: 0.6;
}

.t_event_delete:hover {
    opacity: 1;
    -ms-filter: none;
}

.t_rowmove_handle {
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #ccc;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAKCAYAAACT+/8OAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjExR/NCNwAAAClJREFUGFdj+P//P4O9vX2Bg4NDP4gNFgBytgPxebgAMsYuQGMz/jMAAFsTZDPYJlDHAAAAAElFTkSuQmCC);
    cursor: move;
}

.t_rowmove_source {
    background-color: black;
    opacity: 0.2;
}

.t_rowmove_position_before,
.t_rowmove_position_after {
    background-color: #999;
    height: 2px;
}

.t_rowmove_position_child {
    margin-left: 10px;
    background-color: #999;
    height: 2px;
}

.t_rowmove_position_child:before {
    content: "+";
    color: #999;
    position: absolute;
    top: -8px;
    left: -10px;
}

.t_rowmove_position_forbidden {
    background-color: red;
    height: 2px;
    margin-left: 10px;
}

.t_rowmove_position_forbidden:before {
    content: "x";
    color: red;
    position: absolute;
    top: -8px;
    left: -10px;
}

.t_link_horizontal {
    border-bottom-style: solid;
    border-bottom-color: red;
}

.t_link_vertical {
    border-right-style: solid;
    border-right-color: red;
}

.t_link_arrow_right:before {
    content: "";
    border-width: 6px;
    border-color: transparent transparent transparent red;
    border-style: solid;
    width: 0px;
    height: 0px;
    position: absolute;
}

.t_link_arrow_left:before {
    content: "";
    border-width: 6px;
    border-color: transparent red transparent transparent;
    border-style: solid;
    width: 0px;
    height: 0px;
    position: absolute;
}

.t_link_arrow_down:before {
    content: "";
    border-width: 6px;
    border-color: red transparent transparent transparent;
    border-style: solid;
    width: 0px;
    height: 0px;
    position: absolute;
}

.t_link_arrow_up:before {
    content: "";
    border-width: 6px;
    border-color: transparent transparent red transparent;
    border-style: solid;
    width: 0px;
    height: 0px;
    position: absolute;
}

.t_shadow_overlap .t_shadow_inner {
    background-color: red;
}

.t_overlay {
    background-color: gray;
    opacity: 0.5;
    filter: alpha(opacity=50);
}

.t_event_group {
    box-sizing: border-box;
    font-size: 12px;
    color: #666;
    padding: 2px 2px 2px 2px;
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #fff;
}

.t_header_icon {
    box-sizing: border-box;
    border: 1px solid #ffffff;
    background-color: #ffffff;
    color: #000000;
}

.t_header_icon:hover {
    background-color: #fafafa;
}

.t_header_icon_hide:before {
    content: "\00AB";
}

.t_header_icon_show:before {
    content: "\00BB";
}

.t_rowheader.t_rowheader_selected {
    background-color: #aaa;
    background-image: linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.2) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, 0.2) 50%,
            rgba(255, 255, 255, 0.2) 75%,
            transparent 75%,
            transparent
    );
    background-size: 20px 20px;
}

.t_row_new .t_rowheader_inner {
    cursor: text;
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABUSURBVChTY0ACslAaK2CC0iCQDMSlECYmQFYIAl1AjFUxukIQwKoYm0IQwFCMSyEIaEJpMMClcD4Qp0CYEIBNIUzRPzAPCtAVYlWEDgyAGIdTGBgAbqEJYyjqa3oAAAAASUVORK5CYII=);
}

.t_row_new .t_rowheader_inner:hover {
    background: white;
}

.t_rowheader textarea {
    padding: 3px;
}

.t_rowheader_scroll {
    cursor: default;
}

.t_shadow_forbidden .t_shadow_inner {
    background-color: red;
}

.t_event_moving_source {
    opacity: 0.5;
    filter: alpha(opacity=50);
}

.t_linkpoint {
    background-color: white;
    border: 1px solid gray;
    border-radius: 5px;
}

.t_linkpoint.t_linkpoint_hover {
    background-color: black;
}

.t_event.t_event_version .t_event_inner {
    background-color: #cfdde8;
    background-image: linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.2) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, 0.2) 50%,
            rgba(255, 255, 255, 0.2) 75%,
            transparent 75%,
            transparent
    );
    background-size: 20px 20px;
}

.t_crosshair_vertical,
.t_crosshair_horizontal,
.t_crosshair_left,
.t_crosshair_top {
    background-color: gray;
    opacity: 0.2;
    filter: alpha(opacity=20);
}

.t_link_dot {
    border-radius: 10px;
    background-color: red;
}

.t_task_milestone .t_event_inner {
    position: absolute;
    top: 16%;
    left: 16%;
    right: 16%;
    bottom: 16%;
    background: #38761d;
    border: 0px none;
    transform: rotate(45deg);
    filter: none;
}

.t_event_left {
    white-space: nowrap;
    padding-top: 5px;
    color: #666;
    cursor: default;
}

.t_event_right {
    white-space: nowrap;
    padding-top: 5px;
    color: #666;
    cursor: default;
}

.t_selectionrectangle {
    background-color: #0000ff;
    border: 1px solid #000033;
    opacity: 0.4;
}

.t_link_shadow {
    border: 1px solid black;
}

.t_link_shadow_circle {
    background-color: black;
}

.t_block {
    background-color: #808080;
    opacity: 0.5;
}
