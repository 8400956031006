/* colors */
:root {
    --main-background: #343434;
    --secondary-background: #707070;
    --main-text: #EFEFEF;
    --main-error: #FF0000;
    --shadow: rgba(0, 0, 0, 0.25);
    --shadow-dark: rgba(0, 0, 0, 0.75);
    --disabled-icon: #8D8D8D;
}

.historyItem {
    border-bottom: 1px solid var(--secondary-background);
}

.historyItem:last-child {
    border-bottom: none;
}

.hIcon {
    color: var(--disabled-icon);
}

.hDate {
    text-align: right;
    color: var(--main-background);
}

.historyWrapper {
    max-height: 300px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: white;
    border-radius: 10px;
    z-index: 5000;
    padding: 5px 15px;
}

/* outer div by material-ui */
.popper-ui {
    filter: drop-shadow(5px 5px 5px var(--shadow-dark));
    width: 450px !important;
    margin-right: 5px;
}

.historyWrapper::-webkit-scrollbar {
    width: 10px;
}

.historyWrapper::-webkit-scrollbar-track {
    background-color: var(--secondary-background);
    border-radius: 10px;
    box-shadow: inset 0 0 6px var(--shadow);
}

.historyWrapper::-webkit-scrollbar-thumb {
    background-color: var(--main-text);
    box-shadow: inset 0 0 6px var(--shadow);
    border-radius: 10px;
}