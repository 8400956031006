.navigationIcon {
    margin-top: -30px;

    background-color: #3f51b5;
    border: 4px solid #3f51b5;
    border-radius: 45px;
    width: 30px;
    height: 30px;
}

.leaflet-marker-icon {
    margin: -15px -15px !important;
    padding: 0;
    /* border: none;
    background-color: transparent; */
}

.leaflet-tooltip-bottom {
    margin-top: -3px;
}

.container-circle:before {
    content: "";
    position: absolute;
    display: block;
    width: 30px;
    height: 30px;
    margin-top: -20px;
    border-radius: 45px;
    background-color: #3f51b5;
}

.pulsating-circle:before {
    animation: 1200ms pulse-ring ease-in-out infinite;
}

@keyframes pulse-ring {
    0% {
        opacity: 0.8;
        transform: scale(1);
    }
    80%,
    100% {
        opacity: 0;
        transform: scale(1.8);
    }
}

.leaflet-control-lock,
.leaflet-control-cropfree,
.leaflet-control-layers {
    border-radius: 0 !important;
    min-width: 50px !important;
    height: 50px !important;
    border: 1px solid grey !important;
    color: black !important;
    background-color: white !important;
}

.leaflet-control-cropfree,
.leaflet-control-lock {
    width: 50px !important;
}

.leaflet-control-layers:hover {
    width: 100%;
}

.leaflet-control-zoom-fullscreen,
.leaflet-control-zoom-out,
.leaflet-control-zoom-in {
    border: 1px solid grey !important;
    color: black !important;
    border-radius: 0 !important;
    height: 50px !important;
    width: 50px !important;
    font-size: 25px !important;
    padding-top: 10px;
    padding-left: -3px;

    margin-left: -1px;
    margin-top: -1px;
    margin-bottom: 10px;
}
.leaflet-control-zoom-fullscreen {
    padding-top: 30px !important;
    padding-left: 3px !important;
}

.leaflet-bar {
    border: none !important;
}
