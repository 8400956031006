a.leaflet-control-zoom-in,
a.leaflet-control-zoom-out,
a.leaflet-control-zoom-fullscreen.fullscreen-icon {
    border: 0 !important;
    font-size: 1rem !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
    padding: 0 !important;
    margin: 0 !important;
    line-height: 1.6 !important;
}

a.leaflet-control-zoom-out {
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    font-size: 1.2rem !important;
    line-height: 1.3 !important;
}

a.leaflet-control-zoom-in {
    border-top-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
    /* margin-bottom: 2px !important; */
}

a.leaflet-control-zoom-fullscreen.fullscreen-icon {
    margin-top: 5px !important;
    border-radius: 5px !important;
}

.leaflet-control-zoom.leaflet-bar.leaflet-control {
    box-shadow: 0 0 20px #000000a1;
    background-color: #00000042;
}

a.leaflet-control-zoom-in:after {
    content: "";
    height: 1px;
    position: absolute;
    left: 4px;
    right: 3px;
    top: 1.5rem;
    background: #bdbdbd;
}

.leaflet-bottom.leaflet-right > .leaflet-control {
    display: flex;
}

.leaflet-bottom.leaflet-right > .leaflet-control.leaflet-bar,
.leaflet-bottom.leaflet-right > .leaflet-control.leaflet-control-attribution {
    display: block;
}


.mapIcon{
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
    padding: 2px;
    box-shadow: 0 0 20px #000000a1;
}

.lockIcon {
    cursor: pointer !important;
    padding: 2px !important;
    box-shadow: 0 0 20px #000000a1 !important;
    border-radius: 5px !important;
    background-color: white !important;
}

/* Override Layers Display */

.leaflet-control-layers .leaflet-control-layers-list {
    display: none !important;
}

.leaflet-control-layers.leaflet-control {
    display: none !important;
}

.layers {
    z-index: 1000;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.layers > .mapIcon.hidden-icon {
    display: none;
}

.layers > .mapIcon {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 4rem;
}

.layers > .mapIcon > label {
    flex-basis: 100%;
    text-align: center;
    margin-top: 3px;
    cursor: pointer;
}