html,
body {
    width: 100%;
    font-size: 14px;
}

table {
    table-layout: auto;
}

tbody {
    overflow: scroll !important;
}

fieldset {
    top: 0px !important;
}

.MuiDialog-paper > .ant-alert {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
}

.ant-select-dropdown {
    z-index: 1350 !important;
}

.MuiInputBase-input {
    margin-left: 10px !important;
}

/*
.ant-select-selection {
    margin-top: 15px;
    margin-left: -1px;
    border: none;
    border-bottom: 1px solid currentColor;
    border-radius: 0;
    padding-bottom: 6px;
}
*/
.ant-select-tree-checkbox-inner {
    border-color: rgba(0, 0, 0, 0.54);
}

.ant-select-tree-checkbox-checked > span {
    background-color: rgb(63, 81, 181) !important;
}

.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
    background-color: rgb(63, 81, 181) !important;
}

/*.ant-select-selection:hover {*/
/*    border-bottom: 2px solid black;*/
/*}*/

.ant-select-selection__choice {
    align-items: center;
    background-color: rgb(224, 224, 224) !important;
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: 13px !important;
    border-radius: 13px !important;
    padding: 0px 22px 29px 12px !important;
}

.ant-select-selection__choice__content {
    padding-top: 4px !important;
}

.ant-select-selection__choice__remove {
    padding-top: 5px !important;
}

.ant-tree {
    color: black !important;
}

.ant-tree-title {
    font-size: 0.875rem;
    font-family: Roboto;
    font-weight: 400;
    letter-spacing: 0.01071em;
    line-height: 1.43;
    color: rgba(0, 0, 0, 0.87);
}

.ant-select-selection__clear {
    margin-right: 40px !important;
}

.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before {
    background: transparent !important;
}

.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper.ant-tree-node-selected,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
    color: rgba(0, 0, 0, 0.65) !important;
}

.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-switcher,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-switcher {
    color: rgba(0, 0, 0, 0.65) !important;
}

.MuiOutlinedInput-input {
    margin-left: 2px;
    padding: 7.4px !important;
    font-size: 14px !important;
    font-weight: 300 !important;
    color: rgba(0, 0, 0, 0.65) !important;
}

.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
    border: none !important;
}

/* timeline stuff */
.react-calendar-timeline .rct-dateHeader-primary {
    color: black !important;
}

.ant-input {
    color: black;
}