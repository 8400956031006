

:root {
    --main-background: #343434;
    --secondary-background: #707070;
    --main-text: #EFEFEF;
    --main-error: #FF0000;
    --shadow: rgba(0, 0, 0, 0.25);
    --shadow-middle: rgba(0, 0, 0, 0.5);
    --shadow-dark: rgba(0, 0, 0, 0.75);
    --disabled-icon: #8D8D8D;
}

.routingToolBar {
    position: absolute;
    left: 10px;
    top: 204px;
    z-index: 500;
}

.routingToolBarButtons {
    background-color: white;
    border-radius: 3px;
    padding: 3px;
    filter: drop-shadow(0 0 2px var(--shadow-dark));
}

.routingToolBar div {
    display: inline-block;
}

.routingToolBarExtension {
    padding: 0;
}

.routingToolBarExtension button {
    font-size: 0.8em;
    padding: 5px 8px;
    background-color: #888888;
    color: white;
    border: none;
    margin-left: 1px;
    transition: none;
}

.routingToolBarExtension button:hover {
    filter: none;
    background-color: #999999;
}

.last {
    border-radius: 0 4px 4px 0;
}

.btnOff {
    opacity: 0.65;
}

.btnOff:hover {
    filter: none;
    background-color: #888888 !important;
}

button.btnOff:hover {
    filter: none;
    cursor: initial;
}

.routingDisplay {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 400;
    width: 100%;
    padding-left: 120px;
    background-color: var(--shadow-middle);
    color: white;
}