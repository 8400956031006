:root {
    --main-background: #343434;
    --secondary-background: #707070;
    --separator: #BEBEBE;
    --main-text: #EFEFEF;
    --main-error: #FF0000;
    --shadow: rgba(0, 0, 0, 0.25);
    --shadow-dark: rgba(0, 0, 0, 0.75);
}

.metadataHeader {
    margin-top: 20px;
}

.metadataList {
    height: 50vh;
    margin-top: 20px;
    overflow: auto;
}

.metadataItem {
    border-bottom: 1px solid var(--separator);
    padding: 8px 4px;
}

.metadataValue {
    float: none;
    overflow: hidden;
    text-overflow: ellipsis;
}

.metadataActions {
    float: right;
    margin-top: -30px;
}

.metadataList::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.metadataList::-webkit-scrollbar-track {
    background-color: var(--secondary-background);
    border-radius: 10px;
    box-shadow: inset 0 0 6px var(--shadow);
}

.metadataList::-webkit-scrollbar-thumb {
    background-color: var(--main-text);
    box-shadow: inset 0 0 6px var(--shadow);
    border-radius: 10px;
}