/* colors */
:root {
  --main-background: #343434;
  --secondary-background: #707070;
  --main-text: #EFEFEF;
  --main-error: #FF0000;
  --shadow: rgba(0, 0, 0, 0.25);
  --shadow-dark: rgba(0, 0, 0, 0.75);
  --disabled-icon: #8D8D8D;
}

.tooltipMarker {
  background-color: hsl(0, 0%, 100%, 0.85);
  width: fit-content;
  height: fit-content;
  white-space: nowrap;
  padding: 5px;
  border-radius: 5px;
  filter: drop-shadow(4px 4px 2px var(--shadow));
}

.liveMarker {
	background: white;
	font-weight: bold;
    padding: 5px 5px 5px 35px;
    text-align: center;
	font-size: 1em;
	border-radius: 5px;
	border: 1px solid black;
	position: relative;
    font-family: helvetica, arial, sans-serif;
    width: fit-content;
    white-space: nowrap;
}

.liveMarker:before {
	content: 'AT';
	display: block;
	width: 30px;
	height: 100%;
	background: #063298;
	position: absolute;
	top: 0;
	border-radius: 4px 0 0 4px;
	color: white;
	font-size: .9em;
	line-height: 27px;
    margin-left: -38px;
    border-left: 1px solid black;
}

.liveMarker:after{
    content: "";
    position: absolute;
    top: 100%;
    left:40%;
    border-top: solid 20px black;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
    border-bottom: solid 10px transparent;
}

.zoom6 {
    display: none;
}

.zoom7 {
    font-size: 0.72em;
}

.zoom8 {
    font-size: 0.75em;
}

.zoom9 {
    font-size: 0.8em;
}

.zoom10 {
    font-size: 0.85em;
}

.zoom11 {
    font-size: 0.9em;
}

.zoom12 {
    font-size: 0.95em;
}

.zoom13 {
    font-size: 1em;
}