.leaflet-bottom.leaflet-right {
  z-index: 400 !important;
}

/* colors and variables */
:root { 
    --toolbar-height: 64px; 
  }
  
 
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  #page-wrap a {
    color: white;
  }
  /* checkbox */
  
  .checkBoxDiv {
    float: left;
    margin-right: 5px;
  }
  
  /* list heading */
  
  .headline-wrapper {
    padding: 5px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  /* collapsible */
  
  .collapsible-wrapper {
    background-color: white;
    filter: drop-shadow(2px 2px 2px var(--shadow));
    border: none;
    border-radius: 10px;
    padding: 5px;
    margin-bottom: 10px;
    float: none;
    overflow: hidden;
  }
  
  .collapsible-header {
    width: 100%;
  }
  
  .collapsible-header-button {
    width: 30px;
    float: left;
  }

.collapsible-header-title {
    text-align: left;
    font-size: 16px;
    padding: 0 95px 0 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
  
  .collapsible-header-actions {
    float: right;
    margin-top: -24px;
  }

  .collapsible-header-title-ga {
    text-align: left;
    font-size: 16px;
    padding: 0 170px 0 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

  .collapsible-header-actions-ga {
    float: right;
    margin-top: -2px;
  }
  .collapsible-content {
    overflow: auto;
    width: 100%;
    position: relative;
    padding-top: 5px;
    text-align: center;
    font-size: 15px;
  }
  
  /* time locking */
  
  .lockingTable {
    width: 100%;
  }
  
  .lockingBtn {
    margin: 3px;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    color: var(--main-background);
    border: 2px solid var(--shadow);
    background-color: white;
    font-size: 0.8em;
    font-weight: 600;
  }
  
  .lockingBtnLocked {
    color: var(--main-text);
    background-color: var(--main-error);
    border-color: var(--main-error);
  }
  
  /* buttons */
  
  button {
    cursor: pointer;
    transition: 0.1s ease-out;
  }
  
  button:hover {
    filter: brightness(70%);
  }
  
  /* button bar */
  
  .buttonBarWrapperDiv {
    position: fixed;
    bottom: 0;
    height: var(--toolbar-height);
    background-color: var(--main-text);
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  
  /* pagination bar */
  
  .paginationBarWrapperDiv {
    position: fixed;
    bottom: var(--toolbar-height);
    height: var(--toolbar-height);
    filter: drop-shadow(0 0 5px var(--shadow));
    margin: 0;
    padding: 0;
    text-align: left;
    overflow: hidden;
  }
  
  .paginationBarPageNumber {
    margin: 0 10px;
    color: var(--main-text);
    cursor: pointer;
  }
  
  /* search bar */
  
  .searchBarWrapperDiv {
    position: fixed;
    bottom: var(--toolbar-height);
    background-color: var(--main-text);
    filter: drop-shadow(0 0 5px var(--shadow));
    margin: 0;
    padding: 0;
    text-align: left;
  }
  
  /* simple selection */
  
  .simpleSelectionMenuWrapper {
    margin-left: 10px;
  }
  
  /* layout */
  
  .grid-container {
    display: grid;
    grid-template-areas:
      'main main main main main right';
    grid-gap: 0;
    padding: 0;
  }
  
  #map-card {
    position: relative;
  } 

  .leaflet-pane .leaflet-marker-icon {
    margin-left: -10px !important;
    margin-top: -10px !important;
    /* width: 20px !important;
    height: 20px !important; */
  } 
  /* marker icon when creating  a route*/
  .leaflet-pane .leaflet-glyph-icon {
    margin-left: -12px !important;
    margin-top: -41px !important;
  }

  .mapWrapperDiv {
    margin-top: 10px !important;
    grid-area: main;
    overflow: hidden;
  }

  .listWrapperDiv {
    grid-area: right;
    padding-bottom: 80px;
    background-color: var(--main-text);
    color: var(--main-background);
    z-index: 400;
    overflow: hidden;
    height: 100%;
  }
  
  .scrollableListWrapperDiv {
    margin: 0;
    height: calc(100vh - 2*var(--toolbar-height) - 100px);
    padding: 30px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  /* scrollbar */
  
  .scrollableListWrapperDiv::-webkit-scrollbar {
    width: 10px;
  }
  
  .scrollableListWrapperDiv::-webkit-scrollbar-track {
    background-color: var(--secondary-background);
    box-shadow: inset 0 0 6px var(--shadow);
  }
  
  .scrollableListWrapperDiv::-webkit-scrollbar-thumb {
    background-color: var(--main-text);
    box-shadow: inset 0 0 6px var(--shadow);
    border-radius: 10px;
  }
  
  .overflowControlWrapper {
    overflow: hidden;
    height: inherit;
    /* height: calc(100vh - var(--toolbar-height)); */
    margin: 0;
    padding: 0;
  }
  
  .listWrapperDiv h1 {
    font-size: 1.4em;
    margin-bottom: 15px;
    margin-right: 40px;
  }
  
  /* sidebar dock */
  
  .toggleDockButton {
    position: fixed;
    right: 20px;
    top: 0px;
    margin-top: 150px;
    height: min-content;
    z-index: 450;
    color: var(--main-background);
    background-color: transparent;
    border: none;
    border-radius: 10px;
    padding: 2px;
    background-color: var(--shadow)
  }
  
  .toggleDockButton:hover {
    background-color: var(--shadow)
  }
  
  .divCloseDockButton {
    position: absolute;
    right: 22px;
    top: 20px;
    background-color: var(--main-text);
    filter: drop-shadow(0 0 2px var(--shadow));
    color: var(--main-background);
    border-radius: 10px;
    z-index: 500;
    transition: 0.1s;
  }
  
  .divCloseDockButton:hover {
    background-color: var(--shadow);
  }
  
  .popper-ui {
    z-index: 800;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  
  /* routing machine */
  
  div.leaflet-top.leaflet-right {
    display: none !important;
    width: 0px !important;
    height: 0px !important;
  }
  
  .color-box {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    border-radius: 5px;
  }
  
  .dialogSelectionMenu {
    margin-top: 30px;
    padding: 0;
  }
  
  .presetListTitle {
    display: inline-block;
    margin-left: 5px;
  }
  
  /* hide finish button for polygon creation */
  a[title="Finish drawing"] {
    display: none !important;
    visibility: hidden !important;
  }

  